.u-center-vertically {
    align-items: center;
    display: flex !important;
}

.u-center-horizontally {
    display: flex !important;
    justify-content: center;
}

.u-center {
    align-items: center;
    display: flex !important;
    justify-content: center;
}

