@use '../generic/colors' as *;
@use './bootstrap' as *;

$ng-select-bg: $input-bg;
$ng-select-border: $border-color;
$ng-select-border-radius: 0;
$ng-select-disabled-bg: $input-disabled-bg;
$ng-select-height: $input-height;
$ng-select-highlight: $primary;
$ng-select-selected: lighten($ng-select-highlight, 40);
$ng-select-value-font-size: 0.875rem;
