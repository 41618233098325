@use '../../globals' as *;

.dropdown-menu {
    box-shadow: $dropdown-box-shadow;
    position: relative;

    .dropdown-item {
        font-weight: $font-weight-base;
    }

    &::before {
        background-color: $dropdown-bg;
        content: '';
        height: $dropdown-arrow-size;
        position: absolute;
        left: calc(50% - #{$dropdown-arrow-size * 0.5});
        transform: scaleY(0.75) rotate(45deg);
        width: $dropdown-arrow-size;
        z-index: -1;
    }

    &[data-popper-placement^='top'] {
        margin-bottom: $dropdown-offset-y !important;

        &::before {
            border-bottom: $dropdown-arrow-border-width solid $dropdown-arrow-border-color;
            border-right: $dropdown-arrow-border-width solid $dropdown-arrow-border-color;
            bottom: - $dropdown-offset-y;
        }
    }

    &[data-popper-placement^='bottom'] {
        margin-top: $dropdown-offset-y !important;

        &::before {
            border-left: $dropdown-arrow-border-width solid $dropdown-arrow-border-color;
            border-top: $dropdown-arrow-border-width solid $dropdown-arrow-border-color;
            top: - $dropdown-offset-y;
        }
    }

    &[data-popper-placement$='start'] {

        &::before {
            left: $dropdown-arrow-offset-x;
        }
    }

    &[data-popper-placement$='end'] {

        &::before {
            left: unset;
            right: $dropdown-arrow-offset-x;
        }
    }
}

.dropdown,
.dropup {
    .dropdown-toggle::after {
        display: none;
    }
}
