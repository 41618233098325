@use './globals' as *;

.o-hop-form-group {
    margin-bottom: 1rem;

    label {
        font-weight: $font-weight-bold;
    }

    &.o-hop-form-group--small {
        margin-bottom: 0.5rem;
    }
}
