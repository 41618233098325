@use '../globals' as *;

.c-hop-toast.toast {

    .toast-body {
        display: flex;
        width: 100%;

        .c-hop-toast__icon {
            margin-right: 0.5rem;
        }

        .c-hop-toast__message {
            display: inline-block;
            flex: 1;
            margin: 0;
        }

        .c-hop-toast__close {
            color: $body-color;
            cursor: pointer;
            opacity: 0.4;

            &:hover,
            &:active {
                opacity: 1;
            }
        }
    }

    &:not(.show) {
        display: block;
    }

    &:not(:last-child) {
        margin-bottom: 1rem;
    }
}
