@use '../../globals' as *;

// Layout & components
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
@import 'bootstrap/scss/images';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/accordion';
@import 'bootstrap/scss/alert';
@import 'bootstrap/scss/badge';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/card';
@import 'bootstrap/scss/tables';
@import 'bootstrap/scss/spinners';
@import 'bootstrap/scss/pagination';
@import 'bootstrap/scss/toasts';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/dropdown';
@import 'bootstrap/scss/list-group';
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/tooltip';
@import 'bootstrap/scss/popover';

/*
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/button-group';
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/navbar';
@import 'bootstrap/scss/card';
@import 'bootstrap/scss/breadcrumb';
@import 'bootstrap/scss/progress';
@import 'bootstrap/scss/close';
@import 'bootstrap/scss/modal';
@import 'bootstrap/scss/carousel';
@import 'bootstrap/scss/offcanvas';
*/

// Helpers
@import 'bootstrap/scss/helpers';

// Utilities
@import 'bootstrap/scss/utilities/api';


%heading {
    text-transform: $headings-text-transform;
}
