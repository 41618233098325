@use '../../globals' as *;

$hop-date-picker-day-bg: rgba($black, 0.06) !default;
$hop-date-picker-navigation-icon-size: 1rem !default;
$hop-date-picker-day-size: 2.25rem !default;

ngb-datepicker {
    border: none !important;

    .ngb-dp-header {
        background-color: transparent;

        ngb-datepicker-navigation {

            .ngb-dp-arrow {
                display: none;
            }

            .ngb-dp-navigation-select {
                justify-content: center;

                .form-select {
                    background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"%3E%3Cpath fill="#{url-encode-color($primary)}" d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"/%3E%3C/svg%3E') right bottom/$hop-date-picker-navigation-icon-size no-repeat,
                    url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"%3E%3Cpath fill="#{url-encode-color($primary)}" d="M416 352c-8.188 0-16.38-3.125-22.62-9.375L224 173.3l-169.4 169.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25C432.4 348.9 424.2 352 416 352z"/%3E%3C/svg%3E') right top/$hop-date-picker-navigation-icon-size no-repeat;
                    border: none;
                    flex: none;
                    margin: 0 0.75rem 1rem 0.75rem;
                    padding: 0 2rem 0 0.5rem;
                    width: auto;
                }
            }
        }
    }

    .ngb-dp-content {
        font-size: 0.75rem;

        .ngb-dp-month {

            .ngb-dp-day,
            .ngb-dp-weekday {
                height: $hop-date-picker-day-size;
                margin: 0.25rem;
                width: $hop-date-picker-day-size;
            }

            .ngb-dp-week {

                &.ngb-dp-weekdays {
                    background-color: transparent;
                    border-bottom: none;

                    .ngb-dp-weekday {
                        color: $body-color;
                        font: {
                            family: $font-family-base;
                            style: normal;
                        };
                        text-transform: uppercase;
                    }
                }
            }

            .ngb-dp-day {
                background-color: $hop-date-picker-day-bg;

                &.hidden {
                    background-color: transparent;
                }

                [ngbDatepickerDayView] {
                    align-items: center;
                    border-radius: 0;
                    display: flex;
                    height: inherit;
                    justify-content: center;
                    width: inherit;

                    @include border-radius();

                    &:hover {
                        background-color: $primary;
                        color: $white;
                    }
                }
            }
        }
    }
}
