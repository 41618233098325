// base colors
$black: #000000;
$darkgray: #2a2a2a;
$gray: #707070;
$lightgray: #f0f0f0;
$white: #ffffff;
$primary: #66bb46;
$success: $primary;
$success-light: #f0ffea;
$warning: #ffd239;
$warning-light: #fffaeb;
$danger: #e16207;
$danger-light: #fff2ea;

// common
$body-color: $darkgray;
$border-color: rgba($darkgray, 0.2);
