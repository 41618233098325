@use '../../globals' as *;

.accordion {

    .accordion-item {
        border: $border-width solid $border-color;
        margin-top: 0.625rem;

        .accordion-header {

            .accordion-button {
                padding: 0.5rem 1rem;
            }
        }
    }
}
