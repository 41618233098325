// we need to use import since bootstrap 5 doesn't support @forward yet.
// The 4 files (functions, variables, mixins, utilities) don't produce css though, so this works out just fine.

@use 'sass:list';
@use '../generic/colors' as *;

@import 'bootstrap/scss/functions';

// settings
$enable-rounded: false;

// common
$border-width: 0.0625rem;
$box-shadow: 0 0.125rem 0.3125rem rgba($black, 0.16);
$font-weight-base: 300;
$font-weight-bold: 700;

// headings
$headings-font-weight: 600;
$headings-text-transform: uppercase;


/*****************************
 * BEGIN COMPONENT VARIABLES *
 *****************************/

// accordion
$accordion-button-active-color: $body-color;
$accordion-button-active-bg: $white;
$accordion-icon-color: $primary;
$accordion-icon-active-color: $primary;

// badge
$badge-padding-y: 0.25rem;
$badge-padding-x: 0.5rem;

// button
$btn-padding-x: 1rem;
$btn-padding-x-lg: 2rem;

// card
$card-border-color: $border-color;
$card-spacer-x: 1.5rem;
$card-spacer-y: 1.5rem;

// checkbox
$form-check-input-border: 0.0625rem solid $gray;

// dropdown
$dropdown-arrow-border-color: rgba($black, 0.03);
$dropdown-arrow-border-width: 0.0625rem;
$dropdown-arrow-offset-x: 0.6875rem;
$dropdown-arrow-size: 1rem !default;
$dropdown-border-width: 0;
$dropdown-box-shadow: 0 0.125rem 0.3125rem rgba($black, 0.16);
$dropdown-item-padding-x: 2rem;
$dropdown-offset-y: $dropdown-arrow-size * 0.5;
$dropdown-padding-y: 1rem;

// list
$list-group-border-color: $border-color;
$list-group-border-width: $border-width;
$list-group-color: $body-color;
$list-group-action-hover-color: $body-color;
$list-group-item-padding-y: 1.125rem;
$list-group-item-padding-x: 0.9375rem;

// modal
$modal-backdrop-bg: $darkgray;
$modal-backdrop-opacity: 25%;

// nav
$nav-link-padding-y: 0.4375rem;

// popover
$popover-body-padding-x: $dropdown-item-padding-x;
$popover-body-padding-y: $dropdown-padding-y;
$popover-border-width: 0;

// table
$table-cell-padding-y: 0.375rem;
$table-cell-padding-x: 0.625rem;
$table-striped-bg: rgba($darkgray, 0.05);
$table-accent-bg: $white;
$table-th-font-weight: $font-weight-bold;

/*****************************
 * END COMPONENT VARIABLES *
 *****************************/


@import 'bootstrap/scss/variables';


/*******************************
 * BEGIN FONT FAMILY OVERRIDES *
 *******************************/

// Keep this after import of variables since it extends the default font family list
$font-family-base: list.join('Lato', $font-family-sans-serif);
$font-family-brand: list.join('Oswald', $font-family-sans-serif);

// headings
$headings-font-family: $font-family-brand;

// badge
$badge-font-weight: $font-weight-normal;

// button
$btn-font-family: $font-family-base;

/*****************************
 * END FONT FAMILY OVERRIDES *
 *****************************/


@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';
