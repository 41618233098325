@use '../globals' as *;

.ng-select {

    .ng-select-container {

        .ng-arrow-wrapper {
            background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"%3E%3Cpath fill="#{url-encode-color($primary)}" d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"/%3E%3C/svg%3E') no-repeat;
            height: 1rem;
            margin-right: 0.5rem;
            width: 1rem;

            .ng-arrow {
                display: none;
            }
        }
    }

    &.ng-select-disabled {

        > .ng-select-container {
            background-color: $ng-select-disabled-bg;
        }
    }
}
